import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import customer from './customer'
import contracts from './contracts'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		customer,
		contracts,
	},
	strict: process.env.DEV,
	plugins: [createPersistedState()],
})
