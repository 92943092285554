import createPersistedState from 'vuex-persistedstate'

const defaultStateSelectedContract = () => {
	return {
		value: '',
		text: '',
		codcoligada: '',
		num_venda: '',
		contrato: '',
		coligada_fantasia: '',
		data_venda: '',
		data_cancelamento: '',
		data_quitacao: '',
		situacao: '',
		situacao_descricao: '',
		cliente_coligada: '',
		cliente: '',
		cliente_email: '',
		empreendimento: '',
		empreendimento_fantasia: '',
		empreendimento_nome: '',
		empreendimento_sigla: '',
		empreendimento_municipio: '',
		empreendimento_uf: '',
		quadra: '',
		lote: '',
		lote_area: '',
		lote_matricula: '',
		lote_bic: '',
		valor_venda: '',
		parcelas_mensais: '',
		parcelas_mensais_pagas: '',
		proximo_reajuste: '',
		fatura_link: '',
		fatura_valor: '',
		fatura_vencimento: '',
		proximo_vencimento: '',
		inadimplente: '',
		inadimplente_iptu: '',
		percentual_pago: '',
		liberado_construcao: '',
		escriturado: '',
		taxa_limpeza_contratado: '',
		liberado_limpeza: '',
		status_obra: '',
		taxa_limpeza_valor: '',
		taxa_limpeza_parcelado: '',
		parcelas_atrasadas: '',
		valor_debitos_municipais: '',
		data_debitos_municipais: '',
	}
}

export default {
	namespaced: true,
	state: {
		customerContracts: [],
		selectedContract: {},
		selectedPayment: {},
	},

	getters: {
		getCustomerContracts: state => state.customerContracts,
		getSelectedPayment: state => state.selectedPayment,
		getSelectedContract: state => state.selectedContract,
	},
	mutations: {
		setCustomerContracts(state, payload) {
			state.customerContracts = payload
		},

		setSelectedContract(state, payload) {
			state.selectedContract = payload
		},

		setSelectedPayment(state, payload) {
			state.selectedPayment = payload
		},

		resetSelectedContractState(state) {
			Object.assign(state.selectedContract, defaultStateSelectedContract())
		},
	},
	actions: {
		resetSelectedContract({ commit }) {
			commit('resetSelectedContractState')
		},
	},
	plugins: [createPersistedState()],
}
