import { gql } from 'graphql-tag'
import store from '@/store'
import axios from 'axios'

export const getContracts = async apolloClient => {
	let errors = null
	let contracts = null

	await apolloClient
		.query({
			query: gql`
				query getContracts {
					contract(order_by: { num_venda: desc }) {
						aquisicao_escritura
						codcoligada
						num_venda
						contrato
						coligada_fantasia
						data_venda
						data_cancelamento
						data_quitacao
						situacao
						situacao_descricao
						cliente_coligada
						cliente
						cliente_email
						empreendimento
						empreendimento_fantasia
						empreendimento_nome
						empreendimento_sigla
						empreendimento_municipio
						empreendimento_uf
						quadra
						lote
						lote_area
						lote_matricula
						lote_bic
						valor_venda
						parcelas_mensais
						parcelas_mensais_pagas
						proximo_reajuste
						fatura_link
						fatura_valor
						fatura_vencimento
						proximo_vencimento
						inadimplente
						inadimplente_iptu
						percentual_pago
						liberado_construcao
						escriturado
						taxa_limpeza_contratado
						liberado_limpeza
						status_obra
						taxa_limpeza_valor
						taxa_limpeza_parcelado
						parcelas_atrasadas
						valor_debitos_municipais
						data_debitos_municipais
					}
				}
			`,

			fetchPolicy: 'no-cache',
		})
		.then(contract => {
			contracts = contract.data.contract
		})
		.catch(error => {
			errors = error
		})

	contracts.forEach(element => {
		element.value = element.num_venda
		element.text = 'Contrato: Nº ' + element.contrato
	})

	return {
		data: contracts,
		errors: errors,
	}
}

export const getPayments = async apolloClient => {
	let errors = null
	let payment = null
	const codColigada = store.state.contracts.selectedContract.codcoligada
	const numVenda = store.state.contracts.selectedContract.num_venda

	await apolloClient
		.query({
			query: gql`
				query getPayments($codcoligada: Int, $numvenda: Int) {
					payments(
						where: {
							codcoligada: { _eq: $codcoligada }
							_and: { numvenda: { _eq: $numvenda } }
						}
					) {
						codcoligada
						data_pagamento
						data_vencimento
						descricao
						idlan
						parcela
						valor_aberto
						valor_descontos
						valor_juros_multa
						valor_original
						valor_pago
						numvenda
						situacao_desc
						boleto_link
						ipte
					}
				}
			`,

			variables: {
				codcoligada: codColigada,
				numvenda: numVenda,
			},
			fetchPolicy: 'no-cache',
		})

		.then(payments => {
			payment = payments.data.payments
		})
		.catch(error => {
			errors = error
		})

	if (!payment) {
		errors = 'Não foi possível carregar as parcelas'
	}

	if (!errors) {
		payment.sort(function(a, b) {
			if (a.data_vencimento < b.data_vencimento) {
				return -1
			} else {
				return true
			}
		})
	}

	return {
		data: payment,
		errors: errors,
	}
}

export const getContractAlerts = async apolloClient => {
	let errors = null
	let alert = []
	const codColigada = store.state.contracts.selectedContract.codcoligada
	const numVenda = store.state.contracts.selectedContract.num_venda

	await apolloClient
		.query({
			query: gql`
				query getContractAlerts($codcoligada: Int, $num_venda: Int) {
					contract_alerts(
						where: {
							_and: { codcoligada: { _eq: $codcoligada }, num_venda: { _eq: $num_venda } }
						}
					) {
						mensagem
						tipo
					}
				}
			`,

			variables: {
				codcoligada: codColigada,
				num_venda: numVenda,
			},
			fetchPolicy: 'no-cache',
		})

		.then(alerts => {
			alert = alerts.data.contract_alerts ?? []
		})
		.catch(error => {
			errors = error
		})

	if (!alert) {
		errors = 'Não foi possível carregar as alertas'
	}
	return {
		data: alert,
		errors: errors,
	}
}

export const getLatestPayments = async body => {
	return await axios
		.post('https://api.saobento.com.br/cliente/segunda-via/boletos', body)
		.then(response => response.data.boletos)
		.catch(function(error) {
			throw error
		})
}
export default () => ({
	getContracts,
	getPayments,
	getContractAlerts,
	getLatestPayments,
})
