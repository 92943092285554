import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserState } from '../firebase'

const Login = () => import('../views/Login/index.vue')
const Forgot = () => import('../views/Forgot/index.vue')
const Home = () => import('@/views/Home.vue')
const Financiamento = () => import('@/views/Pages/Financiamento/Financiamento.vue')
const Parcela = () => import('@/views/Pages/Financiamento/Parcela.vue')
const MinhasSolicitacoes = () => import('@/views/Pages/Solicitacoes/Solicitacoes.vue')
const BoletoFacil = () => import('@/views/Pages/BoletoFacil/BoletoFacil.vue')
const DemarcacaoLote = () => import('@/views/Pages/Solicitacoes/DemarcacaoLote/index.vue')
const ProgramaRocada = () => import('@/views/Pages/Solicitacoes/ProgramaRocada/index.vue')
const cessaoDireitos = () => import('@/views/Pages/Solicitacoes/CessaoDireitos/CessaoDireitos.vue')
const perguntasFrequentes = () => import('@/views/Pages/Faq/Faq.vue')
const faleConosco = () => import('@/views/Pages/Contact/Contact.vue')
const Documentos = () => import('@/views/Pages/Documentos/Documentos.vue')
const Error404 = () => import('@/views/error/Error404.vue')
const AccountSetting = () => import('@/views/AccountSetting/index.vue')
const FirstAccess = () => import('@/views/FirstAccess/index.vue')
const DocumentViewer = () => import('@/views/components/DocumentViewer.vue')

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: {
				requiresAuth: true,
				pageTitle: 'Home',
			},
		},

		{
			path: '/documentos',
			name: 'documentos',
			component: Documentos,
			meta: {
				requiresAuth: true,
				pageTitle: 'Documentos',
			},
		},

		{
			path: '/document-viewer',
			name: 'document-viewer',
			props: true,
			component: DocumentViewer,
			meta: {
				layout: 'full',
				requiresAuth: true,
				pageTitle: 'Visulizar Documento',
			},
			beforeEnter: (to, from, next) => {
				if (to.params.data != undefined && to.params.documentName != undefined) {
					next()
				} else {
					next({ name: 'home' })
				}
			},
		},
		{
			path: '/financiamento',
			name: 'financiamento',
			component: Financiamento,
			meta: {
				requiresAuth: true,
				pageTitle: 'Financiamento',
			},
		},
		{
			path: '/parcela',
			name: 'parcela',
			component: Parcela,
		},
		{
			path: '/minhas-solicitacoes',
			name: 'minhas-solicitacoes',
			component: MinhasSolicitacoes,
			meta: {
				requiresAuth: true,
				pageTitle: 'Minhas Solicitações',
			},
		},

		{
			path: '/boleto-facil',
			name: 'boleto-facil',
			component: BoletoFacil,
			meta: {
				requiresUnauth: true,
				layout: 'full',
			},
		},

		{
			path: '/demarcao-de-lote',
			name: 'demarcao-de-lote',
			component: DemarcacaoLote,
			meta: {
				requiresAuth: true,
				pageTitle: 'Demarcação de Lote',
			},
		},
		{
			path: '/programa-rocada',
			name: 'programa-rocada',
			component: ProgramaRocada,
			meta: {
				requiresAuth: true,
				pageTitle: 'Programa de Roçada',
			},
		},
		{
			path: '/duvidas',
			name: 'duvidas',
			component: perguntasFrequentes,
			meta: {
				requiresAuth: true,
				pageTitle: 'Perguntas Frequentes',
			},
		},
		{
			path: '/',
			name: 'account-settings',
			component: AccountSetting,
			meta: {
				requiresAuth: true,
				pageTitle: 'Account Settings',
			},
		},
		{
			path: '/fale-conosco',
			name: 'fale-conosco',
			component: faleConosco,
			meta: {
				requiresAuth: true,
				pageTitle: 'Fale Conosco',
			},
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: {
				requiresUnauth: true,
				layout: 'full',
			},
		},
		{
			path: '/forgot',
			name: 'forgot',
			component: Forgot,
			meta: {
				requiresUnauth: true,
				layout: 'full',
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: Error404,
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/primeiro-acesso',
			name: 'primeiro-acesso',
			component: FirstAccess,
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/cessao-direitos',
			name: 'cessao-direitos',
			component: cessaoDireitos,
			meta: {
				requiresAuth: true,
				pageTitle: 'Transferência',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

// verifica autenticação
router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

	const isAuth = await getUserState()

	if (requiresAuth && !isAuth) next('/login')
	else if (requiresUnauth && isAuth) next('/')
	else next()
})

// splash screen
router.afterEach(() => {
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
