import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VMask from 'v-mask'
import VueCompositionAPI from '@vue/composition-api'
import { createProvider } from '@/apollo'
import { getUserState } from '@/firebase'
import { logout, fetchCustomer } from "@/services/auth"
import VueGtag from "vue-gtag"
import router from "@/router"
import store from "@/store"
import App from "@/App.vue"
import "@/faqDB/db"
import "@/global-components"
import "@/libs/portal-vue"
import "@/libs/toastification"
import "@/registerServiceWorker"
require('@core/assets/fonts/feather/iconfont.css')
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import { setInterval } from 'core-js'

Vue.use(
    VueGtag, {
        config: { id: "G-WY6MK7RZRR" },
    },
    router
)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueGtag, { config: { id: "G-WY6MK7RZRR" } }, router)
    // Composition API
Vue.use(VueCompositionAPI)

Vue.use(VMask)

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

Vue.config.productionTip = false
const apolloProvider = createProvider()

window.zESettings = {
    webWidget: {
        contactForm: {
            title: { "*": "Fale com um atendente" },
        },
        chat: {
            title: { "*": "Fale com um atendente" },
        },
        launcher: {
            label: {
                "*": "Fale com um atendente",
            },

            chatLabel: {
                "*": "Fale com um atendente",
            },

            mobile: {
                labelVisible: false,
            },
        },
    },
}

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        Vue,
        dsn: "https://1ac98a892ad84e6aa193299ca343f05d@o1108544.ingest.sentry.io/6136291",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["cliente.saobento.com.br/"],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}
new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App),
    async created() {
        const userState = await getUserState()
				
        setInterval(async() => {
            let user = await getUserState()
            if (user && user.stsTokenManager.expirationTime > new Date().getTime()) {
                await logout(apolloProvider.defaultClient)
            }
        }, 2000000)

        if (userState) {
            const error = await fetchCustomer(
                userState.uid,
                apolloProvider.defaultClient
            )

            if (error) {
                await logout(apolloProvider.defaultClient)
            }
        }
    },
}).$mount("#app")