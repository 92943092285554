/*eslint-disable*/
module.exports = {
	isEmail: email => {
		emailRgx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		if (emailRgx.test(email)) {
			return true
		} else {
			return false
		}
	},

	isCpfOrCnpj: cpfCnpj => {
		cpfRgx = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)/
		cnpjRgx = /(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/

		if (cpfRgx.test(cpfCnpj) || cnpjRgx.test(cpfCnpj)) {
			return true
		} else {
			if (module.exports.isNumber(cpfCnpj)) {
				if (cpfCnpj.toString().length == 11 || cpfCnpj.toString().length == 14) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		}
	},

	isCpf: cpf => {
		if (cpf.replace(/[. / -]/g, '').trim().length === 11) {
			return true
		}

		return false
	},

	isCnpj: cnpj => {
		if (cnpj.replace(/[. / -]/g, '').trim().length === 14) {
			return true
		}

		return false
	},

	getTreatedCpfOrCnpj: cpfCnpj => {
		return cpfCnpj.replace(/[. / -]/g, '').trim()
	},

	isNumber: value => {
		return !isNaN(parseFloat(value)) && isFinite(value)
	},

	emailMask: email => {
		var maskedEmail = email.replace(/([^@\.])/g, '*').split('')
		var previous = ''
		for (i = 0; i < maskedEmail.length; i++) {
			if (i <= 1 || previous == '.' || previous == '@') {
				maskedEmail[i] = email[i]
			}
			previous = email[i]
		}
		return maskedEmail.join('')
	},
}
