import router from '@/router'
import axios from 'axios'
import store from '@/store'
import { getContracts } from '@/services/contract'
import { onLogin, onLogout } from '@/apollo'
import { gql } from 'graphql-tag'
import {
	isEmail,
	isCpfOrCnpj,
	getTreatedCpfOrCnpj,
	emailMask,
} from '@/utils/loginValidation.js'
import {
	getAuth,
	signInWithEmailAndPassword,
	signOut,
	GoogleAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
	updatePassword,
} from 'firebase/auth'

const auth = getAuth()

export const login = async (user, password, apolloClient) => {
	let uuidUser = null
	let errors = null

	if (!isEmail(user)) {
		let { data } = await getEmailByCpfCnpj(user, apolloClient)
		data != '' ? (user = data) : (errors = 'Usuário ou senha incorreta!')
	}

	await signInWithEmailAndPassword(auth, user, password)
		.then(userCredentials => {
			uuidUser = userCredentials.user.uid
		})
		.catch(() => {
			errors = 'Usuário ou senha incorreta!'
		})

	if (!errors && uuidUser) {
		await onLogin(apolloClient)

		let { data } = await getContracts(apolloClient)
		if (data?.length > 0) {
			await store.commit('contracts/setCustomerContracts', data)
			errors = await fetchCustomer(uuidUser, apolloClient)
		} else {
			errors = 'Nenhum contrato encontrado para este usuário!'
			logout(apolloClient)
		}
	}

	return { errors }
}

export const loginGoogle = async apolloClient => {
	const provider = new GoogleAuthProvider()
	let uuidUser = null
	let photoURL = null
	let errors = null

	await signInWithPopup(auth, provider)
		.then(userCredentials => {
			uuidUser = userCredentials.user.uid
			photoURL = userCredentials.user.photoURL

			if (userCredentials._tokenResponse) {
				var isNewUser = userCredentials._tokenResponse.isNewUser

				if (isNewUser) {
					userCredentials.user.delete()
					store.commit('customer/setLoggedCustomer', {})
					logout(apolloClient)
					errors = 'Usuário não encontrado na base de dados!'
				}
			}
		})
		.catch(error => {
			errors = error.message
		})

	if (!errors && uuidUser) {
		await onLogin(apolloClient)

		let { data } = await getContracts(apolloClient)
		if (data?.length > 0) {
			await store.commit('contracts/setCustomerContracts', data)
			errors = await fetchCustomer(uuidUser, apolloClient, photoURL)
		} else {
			errors = 'Nenhum contrato encontrado para este usuário'
			logout(apolloClient)
		}
	}

	return { errors }
}

const getEmailByCpfCnpj = async (user, apolloClient) => {
	let email,
		cpfCnpj,
		errors = ''

	if (isCpfOrCnpj(user)) {
		cpfCnpj = getTreatedCpfOrCnpj(user)
		await apolloClient
			.query({
				query: gql`
					query getCustomerEmail($user_login: String!) {
						customer_email(where: { user_login: { _eq: $user_login } }) {
							user_email
						}
					}
				`,
				variables: {
					user_login: cpfCnpj,
				},
				fetchPolicy: 'no-cache',
			})
			.then(response => {
				email = response.data['customer_email'][0]['user_email']
			})
			.catch(() => {
				errors = 'Usuário ou senha incorreta!'
			})
	} else {
		errors = 'CPF/CNPJ inválido!'
	}

	return {
		data: email,
		errors: errors,
	}
}

export const fetchCustomer = async (uuidUser, apolloClient, photoURL) => {
	let errors = null
	let customer = null

	await apolloClient
		.query({
			query: gql`
				query getCustomer($firebase_uid: String!) {
					customer(where: { firebase_uid: { _eq: $firebase_uid } }) {
						uuid
						name
						first_name
						email
						image
						role
						codcolcfo
						codcfo
					}
				}
			`,
			variables: {
				firebase_uid: uuidUser,
			},
			fetchPolicy: 'no-cache',
		})
		.then(customers => {
			// authenticateZenvia(customers.data['customer'][0].name, customers.data['customer'][0].email)
			window.altubots.sendDataToContext({"name": customers.data['customer'][0].name
			})
			customer = customers.data['customer'][0]
			if (photoURL) {
				customer.image = photoURL
				updateCustomerPhotoURL(customer.uuid, photoURL, apolloClient)
			}
		})
		.catch(() => {
			errors = 'Usuário não encontrado'
		})

	if (customer) {
		store.commit('customer/setLoggedCustomer', customer)
		// await authenticateMovidesk()

		store.commit('contracts/setSelectedContract', {
			...store.state.contracts.customerContracts[0],
			value: store.state.contracts.customerContracts[0].num_venda,
			text: 'Contrato: Nº ' + store.state.contracts.customerContracts[0].contrato,
		})

		router.currentRoute.path == '/login' && router.push('/')
	} else {
		store.commit('customer/setLoggedCustomer', {})
		logout(apolloClient)
		errors = 'Usuário não encontrado!'
	}

	return errors
}

export const logout = async apolloClient => {
	store.dispatch('contracts/resetSelectedContract')
	store.commit('customer/setLoggedCustomer', {})
	store.commit('contracts/setCustomerContracts', {})
	store.commit('contracts/setSelectedPayment', {})
	await signOut(auth)
	await onLogout(apolloClient)
	window.altubots.sendDataToContext({"name": null
	})
	router.currentRoute.path !== '/login' && router.push('/login')
}

const sendResetEmail = async (login, apolloClient) => {
	let errors = null

	if (!isEmail(login)) {
		let { data } = await getEmailByCpfCnpj(login, apolloClient)
		data != '' ? (login = data) : (errors = 'Usuário ou senha incorreta!')
	}

	await sendPasswordResetEmail(auth, login)
		.then(() => {
			errors = null
		})
		.catch(error => {
			errors = error.message
		})

	return { errors }
}

const updateCustomerPhotoURL = async (uuidUser, photoURL, apolloClient) => {
	await apolloClient.mutate({
		mutation: gql`
			mutation updateCustomer($uuid: uuid!, $image: String!) {
				update_customer_by_pk(pk_columns: { uuid: $uuid }, _set: { image: $image }) {
					uuid
				}
			}
		`,
		variables: {
			uuid: uuidUser,
			image: photoURL,
		},
	})
}

const changeUserPassword = async newPassword => {
	const auth = getAuth()
	const user = auth.currentUser
	let errors = null

	await updatePassword(user, newPassword)
		.then(() => {
			errors = null
		})
		.catch(error => {
			errors = error.message
		})

	return { errors }
}

const getMaskedEmailByCpfCnpj = async (user, apolloClient) => {
	let { data } = await getEmailByCpfCnpj(user, apolloClient)
	return emailMask(data)
}

// const authenticateZenvia = async (nome, email) => {
// 	await window.altubots.sendDataToContext({
// 		"name": nome,
// 		"email": email
//   	})
// }

const verifyIfCpfOrCnpjExists = async cpfCnpj => {
	return await axios
		.post('https://api.saobento.com.br/cliente/segunda-via/cliente', {
			cpfCnpj,
		})
		.then(response => response)
		.catch(function(error) {
			throw error
		})
}

export default () => ({
	login,
	loginGoogle,
	logout,
	fetchCustomer,
	sendResetEmail,
	updateCustomerPhotoURL,
	changeUserPassword,
	getEmailByCpfCnpj,
	getMaskedEmailByCpfCnpj,
	verifyIfCpfOrCnpjExists,
})
