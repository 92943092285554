import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api'

const firebaseConfig = {
    apiKey: "AIzaSyBK--Ecdpq3Mb4bwHe7nZDh-INm4WqGqCI",
    authDomain: "portais-saobento.firebaseapp.com",
    projectId: "portais-saobento",
    storageBucket: "portais-saobento.appspot.com",
    messagingSenderId: "837669273070",
    appId: "1:837669273070:web:326a07151e852ede03a2ff",
    measurementId: "G-TMLW095N2L"
}

export const firebaseApp = initializeApp(firebaseConfig)

// estado do usuário, usado no router
export const getUserState = () =>
    new Promise((resolve, reject) =>
        onAuthStateChanged(getAuth(), resolve, reject)
    )


// usuario completo para uso no home
// import { useAuthState } from '../firebase'
// const { user } = useAuthState()
export const useAuthState = () => {

    const user = ref(null)
    const error = ref(null)
    const auth = getAuth()

    let unsubscribe

    onMounted(() => {
        unsubscribe = onAuthStateChanged(
            auth,
            u => (user.value = u),
            e => (error.value = e)
        )
    })

    onUnmounted(() => unsubscribe())
    const isAuthenticated = computed(() => user.value != null)
    return { user, error, isAuthenticated }
}
